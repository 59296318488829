import moment from 'moment'
import Sortable from 'sortablejs'
import * as Utils from './utils.js'
import { WarmUp } from './warm_up_class.js'
import { Round } from './round_class.js'
import { Cooldown } from './cooldown_class.js'

export class GymClass {
  constructor() {
    this.form = document.querySelector('form[class*=new_gym_class], form[class*=edit_gym_class]')

    if (this.form) {
      Utils.setDurationFromSeconds()

      this.initWarmUp()
      this.initCooldown()
      this.initRounds()
      this.initSortable()

      this.listenToEvents()
      this.form.addEventListener('submit', (event) => {
        event.preventDefault()
        Utils.addHiddenDurationInputs()
        this.form.submit()
      })

      this.autoPopulateLessonPlanDate()

      Utils.refreshDurationPickers()
      this.updateDuration()

      this.updateSectionsBackground()
    }
  }

  initWarmUp() {
    this.warmUp = document.querySelector('#warm_up')

    this.warmUpInstance = new WarmUp(this.warmUp)

    this.warmUp.addEventListener('duration-update', () => this.updateDuration())
  }

  initRounds() {
    this.rounds = document.querySelector('#rounds')
    this.roundsNumberInput = document.querySelector('#rounds-number')
    this.roundsContainer = this.rounds.querySelector('#rounds-container')

    this.roundsNumber = this.rounds.dataset.roundsNumber
    this.roundFields = Utils.unescapeHTML(this.rounds.dataset.roundFields)

    this.roundsInstance = []
    this.roundsContainer.querySelectorAll('.round').forEach(round => {
      this.roundsInstance.push(new Round(round))

      round.addEventListener('duration-update', () => {
        this.updateDuration()
        this.updateInteractionAndTiming()
      })
      round.addEventListener('round-copied', (event) => this.setRoundToCopy(event.target))
      round.addEventListener('round-pasted', (event) => this.pasteCopiedRoundtoRound(event.target))
      round.addEventListener('round-deleted', (event) => this.deleteRound(event.detail.roundNumber))
    })

    this.roundsNumberInput.addEventListener('change', (event) => {
      event.preventDefault()
      this.roundsNumberChange()
    })

    this.pasteRoundButton = this.rounds.querySelector('#paste-round')
    this.pasteRoundButton.addEventListener('click', (event) => {
      event.preventDefault()
      this.pasteRound()
    })

    if (this.roundsNumber == 0) {
      this.addRounds(1)
      this.roundsNumber = 1
      this.roundsNumberInput.value = 1
    }
    this.updateRoundNumbers()
  }

  initCooldown() {
    this.cooldown = document.querySelector('#cooldown')

    this.cooldownInstance = new Cooldown(this.cooldown)

    this.cooldown.addEventListener('duration-update', () => this.updateDuration())
  }

  initSortable() {
    this.sections = this.form.querySelector('#gym-class-sections')

    Sortable.create(this.sections, {
      handle: '.reorder-section-button',
      onEnd: () => {
        this.updateSectionsOrder()
        this.updateSectionsBackground()
      }
    })
  }

  listenToEvents() {
    this.form.querySelector('#gym_class_welcome_duration').addEventListener('change', () => {
      this.updateDuration()
    })

    this.form.querySelector('#gym_class_workout_instructions_duration').addEventListener('change', () => {
      this.updateDuration()
    })

    this.form.querySelector('#gym_class_workout_instructions_rest_time').addEventListener('change', () => {
      this.updateDuration()
    })
  }

  updateDuration() {
    let duration = this.warmUpInstance.duration + this.cooldownInstance.duration;

    let welcomeDuration = this.form.querySelector('#gym_class_welcome_duration')
    duration += Utils.durationToSeconds(welcomeDuration.value)

    let instructionsDuration = this.form.querySelector('#gym_class_workout_instructions_duration')
    duration += Utils.durationToSeconds(instructionsDuration.value)

    let instructionsRestTime = this.form.querySelector('#gym_class_workout_instructions_rest_time')
    duration += Utils.durationToSeconds(instructionsRestTime.value)

    this.roundsInstance.forEach(round => {
      duration += round.duration
    })

    this.form.querySelector('#gym_class_duration').value = Utils.secondsToDuration(duration)
  }

  roundsNumberChange() {
    if (this.roundsNumberInput.value < 1) { return }

    const roundsAfterChange = parseInt(this.roundsNumberInput.value) - this.roundsNumber

    if (roundsAfterChange < 0) {
      this.removeRounds(roundsAfterChange)
      this.updateDuration()
    } else if (roundsAfterChange > 0) {
      this.addRounds(roundsAfterChange)
    }

    this.roundsNumber = this.roundsNumberInput.value
    this.updateInteractionAndTiming()
    this.updateRoundNumbers()
  }

  removeRounds(number) {
    for(let i = 0; i < Math.abs(number); i++) {
      const lastRound = this.getLastRound()

      if (lastRound) {
        const destroyInput = lastRound.querySelector('input[type=hidden]')


        destroyInput.value = true

        this.roundsContainer.append(destroyInput)
        this.roundsInstance.pop()
        lastRound.remove()
      }
    }
  }

  addRounds(number) {
    for(let i = 0; i < number; i++) {
      const regex = new RegExp(/NEW_ROUND_ID/g)

      let roundFields = this.roundFields.replace(
        'Round NEW_ROUND_ID',
        `Round ${parseInt(this.roundsNumber) + i + 1}`
      )
      roundFields = roundFields.replace(regex, parseInt(this.roundsNumber) + i)

      $(this.roundsContainer).append(roundFields)

      const addedRound = this.getLastRound()

      addedRound.addEventListener('duration-update', () => {
        this.updateInteractionAndTiming()
        this.updateDuration()
      })
      addedRound.addEventListener('round-copied', (event) => this.setRoundToCopy(event.target))
      addedRound.addEventListener('round-pasted', (event) => this.pasteCopiedRoundtoRound(event.target))
      addedRound.addEventListener('round-deleted', (event) => this.deleteRound(event.detail.roundNumber))

      this.roundsInstance.push(new Round(addedRound))
    }
  }

  getLastRound() {
    return this.roundsContainer.querySelector('.round:last-of-type')
  }

  autoPopulateLessonPlanDate() {
    const gymClassDate = this.form.querySelector('#gym_class_class_date')
    const lessonPlanDate = this.form.querySelector('#gym_class_lesson_plan_attributes_date')

    gymClassDate.addEventListener('change', () => {
      const date = moment(gymClassDate.value).format('dddd, MMMM DD, YYYY').toString()

      lessonPlanDate.innerHTML = `<div>${date}</div>`
    })
  }

  setRoundToCopy(roundToCopy) {
    this.roundToCopy = roundToCopy
    this.pasteRoundButton.classList.add('d-block')
  }

  pasteCopiedRoundtoRound(target) {
    if (this.roundToCopy) {
      this.pasteRoundToRound(target)
    }
  }

  pasteRound() {
    const roundCopy = this.prepareRoundToPaste(null)

    Utils.setDurationFromSeconds(roundCopy)
    this.roundsInstance.push(new Round(roundCopy))

    $(this.roundsContainer).append(roundCopy)

    this.roundsNumber++
    this.roundsNumberInput.value = this.roundsNumber

    this.updateDuration()
    this.updateRoundNumbers()
    this.updateInteractionAndTiming()

    roundCopy.scrollIntoView()
  }

  pasteRoundToRound(target) {
    const targetRound = parseInt(target.querySelector('.round-title').textContent.charAt(target.querySelector('.round-title').textContent.length-1))

    if (targetRound) {
      const roundCopy = this.prepareRoundToPaste(targetRound)
      const idInput = this.roundsContainer.querySelectorAll(':scope > input[type=hidden]')[targetRound - 1]
      const destroyInput = target.querySelector('input[type=hidden]')
      this.roundsContainer.append(destroyInput)

      const rand = (Math.random() * 1000).toFixed()

      if (idInput) {
        idInput.name = idInput.name.replace(/\d/g, rand)
      }
      destroyInput.name = destroyInput.name.replace(/\d/g, rand)
      destroyInput.value = true

      Utils.setDurationFromSeconds(roundCopy)
      this.roundsInstance[targetRound-1] = new Round(roundCopy)
      const oldItem = this.roundsContainer.querySelectorAll('.round')[targetRound - 1]
      oldItem.parentNode.replaceChild(roundCopy,oldItem)

      this.updateDuration()
      this.updateInteractionAndTiming()
      this.updateRoundNumbers()
      document.querySelector('.tooltip').remove()
    }
  }

  prepareRoundToPaste(roundNumber) {
    if (roundNumber == null) {
      roundNumber = parseInt(this.roundsNumber) + 1
    }
    let roundCopy = this.cloneDynamicState(this.roundToCopy.cloneNode(true))

    const namesExpression = new RegExp(/\[rounds_attributes\]\[\d\]/g)
    const idsExpression = new RegExp(/\_rounds_attributes\_\d\_/g)

    roundCopy = roundCopy.replace(
      namesExpression,
      `[rounds_attributes][${roundNumber-1}]`
    )
    roundCopy = roundCopy.replace(
      idsExpression,
      `_rounds_attributes_${roundNumber-1}_`
    )

    roundCopy = $(roundCopy)[0]
    roundCopy.querySelector('.round-title').innerHTML = `Round ${roundNumber}`
    roundCopy.querySelectorAll('input[name*="[id]"]').forEach(input => { input.remove() })
    roundCopy.querySelectorAll('.select2').forEach(select => { select.remove() })
    roundCopy.querySelector('.gym-class-round-input').value = roundNumber - 1
    roundCopy.addEventListener('round-copied', (event) => this.setRoundToCopy(event.target))
    roundCopy.addEventListener('round-pasted', (event) => this.pasteCopiedRoundtoRound(event.target))
    roundCopy.addEventListener('round-deleted', (event) => this.deleteRound(event.detail.roundNumber))

    return roundCopy
  }

  cloneDynamicState(roundCopy) {
    roundCopy.querySelectorAll('input, select').forEach(input => {
      let queryExpression

      if (input.id.length) {
        queryExpression = `#${input.id}`
      } else if (input.name.length) {
        queryExpression = `[name="${input.name}"]`
      } else {
        queryExpression = `[class="${input.className}"]`
      }

      let originalInput = this.roundToCopy.querySelector(queryExpression)

      if (!originalInput) { return }

      if(input.tagName == 'INPUT') {
        if (input.classList.contains('html-duration-picker')) {
          input.setAttribute('value', Utils.durationToSeconds(originalInput.value))
        } else {
          input.setAttribute('value', originalInput.value)
        }
      } else {
        originalInput.querySelectorAll('option').forEach(option => {
          const clonedOption = input.querySelector(`[value="${option.value}"]`)
          clonedOption.removeAttribute('data-select2-id')

          if(option.selected) {
            clonedOption.setAttribute('selected', 'selected')
          }
        })
      }
    })

    return roundCopy.outerHTML
  }

  deleteRound(roundNumber) {
    if (roundNumber == null) {
      return;
    }
    const itemToDelete = document.querySelectorAll('.round')[roundNumber]
    const destroyInput = itemToDelete.querySelector('input[type=hidden]')
    destroyInput.value = true
    this.roundsContainer.append(destroyInput)
    this.roundsInstance.splice(roundNumber, 1)
    itemToDelete.remove()
    
    this.roundsNumberInput.value = this.roundsInstance.length
    this.roundsNumber = this.roundsInstance.length
    this.updateDuration()
    this.updateRoundNumbers()
  }

  updateRoundNumbers() {
    for (var i = 0; i < this.roundsContainer.querySelectorAll('.round').length; i++) {
      var roundObject = this.roundsContainer.querySelectorAll('.round')[i]
      roundObject.querySelector('.round-title').innerText = "Round " + (i+1)
      roundObject.querySelector('.gym-class-round-input').value = i
    }
  }

  updateSectionsOrder() {
    const orderInputs = this.form.querySelectorAll('input[name*="gym_class[sections_order][]"]')
    orderInputs.forEach((input) => input.remove())

    for (let child of this.sections.children) {
      const input = `<input type="hidden" name="gym_class[sections_order][]" value="${child.id}">`

      $(this.form).append($(input)[0])
    }
  }

  updateSectionsBackground() {
    const children = [...this.sections.children]
    var isRoundEven = (children.indexOf(this.rounds) % 2) == 0

    children.forEach((child, index) => {
      const condition = isRoundEven ? (index % 2 != 0): (index % 2 == 0)

      if (condition) {
        child.classList.add('bg-light')
      } else {
        child.classList.remove('bg-light')
      }
    })
  }

  updateInteractionAndTiming() {
    let allStationsMatchTime = true

    for (let round of this.roundsInstance) {
      if (!round.stationsMatchTime()) {
        allStationsMatchTime = false
        break
      }
    }

    if (this.roundsMatchTime() && allStationsMatchTime) {
      this.setInteractionAndTimingText('equal')
    } else if (allStationsMatchTime) {
      this.setInteractionAndTimingText('partially-different')
    } else {
      this.setInteractionAndTimingText('different')
    }
  }

  roundsMatchTime() {
    if (this.roundsInstance.length) {
      let previousRestTime = Utils.durationToSeconds(this.roundsInstance[0].restTime.value)
      let previousTimeToMove = Utils.durationToSeconds(this.roundsInstance[0].timeToMove.value)

      for (let round of this.roundsInstance) {
        const restTime = Utils.durationToSeconds(round.restTime.value)
        const timeToMove = Utils.durationToSeconds(round.timeToMove.value)

        if (previousRestTime == restTime && previousTimeToMove == timeToMove) {
          previousRestTime = restTime
          previousTimeToMove = timeToMove
        } else {
          return false
        }
      }

      return true
    }
  }

  setInteractionAndTimingText(likehood) {
    const interactionAndTiming = this.form.querySelector(
      '#gym_class_lesson_plan_attributes_interaction_and_timing'
    )
    let text

    switch(likehood) {
      case 'equal':
        const round = this.roundsInstance[0]
        const stationDuration = round.container.querySelector('input[name*=time_at_station]').value

        text = `
          Members will work for ${this.durationSuffix(stationDuration)} at
          each station, rest ${this.durationSuffix(round.restTime.value)}, have a
          ${this.durationSuffix(round.timeToMove.value)} break between rounds and, work for
          ${this.roundsInstance.length} ${this.roundsInstance.length == 1 ? 'round' : 'rounds'}.
        `

        break
      case 'partially-different':
        text = `
          Members will work for ${this.roundsInstance.length}
          ${this.roundsInstance.length == 1 ? 'round' : 'rounds'}.
        `

        this.roundsInstance.forEach((round, index) => {
          text += `
            On round ${index + 1}, members will work for
            ${this.durationSuffix(Utils.secondsToDuration(round.duration))}, rest/rotate
            ${this.durationSuffix(round.restTime.value)}, and have a
            ${this.durationSuffix(round.timeToMove.value)} break between rounds.
          `
        })

        break
      case 'different':
        text = `
          Members will work for ${this.roundsInstance.length}
          ${this.roundsInstance.length == 1 ? 'round' : 'rounds'}.
        `

        this.roundsInstance.forEach((round, index) => {
          let stationsTime = round.container.querySelectorAll('input[name*=time_at_station]')
          stationsTime = Array.from(stationsTime).map((input) => {
            return this.durationSuffix(input.value)
          })

          text += `
            On round ${index + 1}, members will work for ${stationsTime.join(' / ')},
            rest/rotate ${this.durationSuffix(round.restTime.value)}, and
            have a ${this.durationSuffix(round.timeToMove.value)} break between rounds.
          `
        })

        break
    }

    interactionAndTiming.innerHTML = text
  }

  durationSuffix(duration) {
    if (duration == '00') {
      duration = '0'
    } else if (duration.slice(0,2) == '00') {
      duration = `${duration.slice(3,5)} seconds`
    } else if (duration == '01:00' || duration == '1:00') {
      duration += ' minute'
    } else {
      duration += ' minutes'
    }

    return duration
  }
}
