import ohSnap from 'src/ohsnap.js';

export class GymClassControl {
  constructor(select_loop_list_id, start_loop_list_id) {
    this.select_loop_list_id = select_loop_list_id
    this.start_loop_list_id = start_loop_list_id

    this.init()
  }

  init() {
    this.start_loop_list = document.querySelector("#" + this.start_loop_list_id)
    this.start_loop_list.addEventListener('click', (event) => {
      event.preventDefault()
      this.on_loop_list_start_click()
    })
  }

  on_loop_list_start_click() {
    this.select_loop_list = document.querySelector("#" + this.select_loop_list_id)
    var loopListID = this.select_loop_list.value;
    if (loopListID == "") return;
    $.ajax({
      url: '/loop_lists/' + loopListID + '/activate',
      method: 'POST',
      beforeSend: function(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
      },
      success: function(data){
        if (data['success'] == 'true') {
          ohSnap("Playlist " + data['name'] + " Started", { color: 'green' });
        } else {
          ohSnap("Unable to start playlist", { color: 'red' });
        }
      }
    });
  }
  
}
