$(document).ready(function(){

  if (!$('body').hasClass('loop_list_items-index')) return;

  function importFormForNewLoopListItem(){
    $.ajax({
      url: '/loop_list_items/new?form_type=' + $(this).attr('data-form-type'),
      method: 'GET',
      success: function(data){
        $('#loop-list-item-form-container').html(data);
      }
    });
  };

  function importFormForLoopListItem(loop_list_id, form_type){
    $.ajax({
      url: '/loop_list_items/' + loop_list_id + '/edit?form_type=' + form_type,
      method: 'GET',
      success: function(data){
        $('#loop-list-item-form-container').html(data);
      }
    });
  };

  $('.edit-loop-list-item').on('click', function(){
    var loop_list_id = $(this).attr('data-id');
    var form_type = $(this).attr('data-form-type');
    importFormForLoopListItem(loop_list_id, form_type);
  });

  $('#new-custom-loop-list-item, #new-asset-loop-list-item').on('click', importFormForNewLoopListItem);

  ///////////////////////////////////////
  ///
  /// VALIDATIONS
  ///
  ///////////////////////////////////////

  var valid = true;
  var messages = [];

  function isValidTime(time){
    return /^(|[0-5])[0-9]:[0-5][0-9]$/.test(time);
  }

  function validateTimeFields(timeFields){
    $(timeFields).each(function(i){
      if (!isValidTime($(this).val())){
        valid = false;
        $(this).addClass('form-invalid-field');
        messages.push('You have entered an invalid time.');
      };
    });
  }

  function displayMessages(){
    $("#form-error-messages").html(messages.join('. '));
  }

  function resetInvalidatedFields(){
    $('.form-invalid-field').removeClass('form-invalid-field');
  };

  function onSubmitClickHandler(event){
    var timeFields = $('input[data-validate="time"]');

    // Reset previous validations
    resetInvalidatedFields();
    valid = true;
    messages = [];
    displayMessages();

    // Validate order
    if (!$('#loop_list_item_order').val()) {
      valid = false;
      $('#loop_list_item_order').addClass('form-invalid-field');
      messages.push('Please enter order.');
    }
    validateTimeFields(timeFields);

    if (!valid){
      displayMessages();
      event.preventDefault();
    };
  };

  $('#loop-list-item-form-container').on('click', 'input[type="submit"]', onSubmitClickHandler);

});