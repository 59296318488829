import moment from 'moment'
import * as HTMLDurationPicker from 'html-duration-picker'
//import momentDurationFormatSetup from 'moment-duration-format';
//momentDurationFormatSetup(moment);

export function unescapeHTML(string) {
  var element = document.createElement("span");
  element.innerHTML = string;

  return element.innerText;
}

export function refreshDurationPickers() {
  HTMLDurationPicker.refresh()
}

export function setDurationFromSeconds(element = document) {
  element.querySelectorAll('.html-duration-picker').forEach(input => {
    const value = input.getAttribute('value')

    input.value = secondsToDuration(value)
  })
}

export function addHiddenDurationInputs() {
  document.querySelectorAll('.html-duration-picker').forEach(input => {
    cloneInputAsHidden(input)
  })
}

export function durationToSeconds(value) {
  const duration = value.split(':')

  return moment.duration({ minutes: duration[0], seconds: duration[1] }).asSeconds()
}

export function secondsToDuration(seconds) {
  seconds = moment.duration(seconds, 'seconds')
  return moment.utc(seconds.asMilliseconds()).format('mm:ss').toString()
  //return moment.duration(seconds, 'seconds').format('mm:ss').toString()
}

function cloneInputAsHidden(input) {
  const parentElement = input.parentElement

  let hiddenInput = document.createElement('input')
  hiddenInput.type = 'hidden'
  hiddenInput.value = durationToSeconds(input.value)
  hiddenInput.name = input.name

  input.removeAttribute('name')

  parentElement.insertBefore(hiddenInput, input)
}
