import { secondsToDuration, durationToSeconds } from './utils.js'
import { WarmUpExercises } from './warm_up_exercises_class.js'

export class WarmUp {
  constructor(container) {
    this.container = container

    if (this.container) {
      this.exercisesElement = this.container.querySelector('.exercises')
      this.exercisesList = this.exercisesElement.querySelector('.list')
      this.exercisesInstance = new WarmUpExercises(this.exercisesElement)

      this.timePerExercise = this.container.querySelector('input[name*=time_per_exercise]')
      this.restTime = this.container.querySelector('input[name*=rest_time]')
      this.rounds = this.container.querySelector('input[name*=rounds]')
      this.description = this.container.querySelector('input[name*=description]')

      this.duration = 0
      this.calculateDuration()

      this.initPresets()
      this.listenToEvents()
    }
  }

  initPresets() {
    this.presetSelector = this.container.querySelector('.preset-select')
    this.presetName = this.container.querySelector('.preset-name')

    this.savePresetButton = this.container.querySelector('.save-preset')
    this.savePresetButton.addEventListener('click', (event) => {
      event.preventDefault()
      this.savePreset()
    })

    $(this.presetSelector).select2({ theme: 'bootstrap4' })
    $(this.presetSelector).on('select2:select', () => this.loadPreset())
  }

  listenToEvents() {
    this.exercisesElement.addEventListener('duration-update', () => this.calculateDuration())
    this.rounds.addEventListener('change', () => this.calculateDuration())
    this.timePerExercise.addEventListener('change', () => this.calculateDuration())
    this.restTime.addEventListener('change', () => this.calculateDuration())
  }

  calculateDuration() {
    const exercisesNumber = this.exercisesElement.dataset.exercisesNumber
    const timePerExercise = this.timePerExercise.value
    const restTime = this.restTime.value
    const rounds = this.rounds.value

    this.duration = (durationToSeconds(timePerExercise) *
                    parseInt(exercisesNumber) *
                    parseInt(rounds)) +
                    durationToSeconds(restTime)

    this.container.dispatchEvent(new Event('duration-update'))
  }

  loadPreset() {
    let alert = confirm('Do you want to load a new warm up preset?')

    if (this.presetSelector.value.length && alert) {
      $.ajax({
        url: `/gym_classes/warm_up_presets/${this.presetSelector.value}`
      })
      .done(presetData => {
        this.timePerExercise.value = secondsToDuration(presetData['time_per_exercise'])
        this.restTime.value = secondsToDuration(presetData['rest_time'])
        this.exercisesInstance.loadPresetExercises(presetData['exercises'])
        this.rounds.value = presetData['rounds']

        const descriptionId = '#gym_class_warm_up_attributes_description'
        this.container.querySelector(descriptionId).innerHTML = presetData['description']
      })
    } else if (!alert) {
      this.presetSelector.value = ''
    }
  }

  savePreset() {
    let presetBody = {
      rounds: this.rounds.value,
      time_per_exercise: durationToSeconds(this.timePerExercise.value),
      rest_time: durationToSeconds(this.restTime.value),
      description: this.description.value,
      preset: true,
      preset_name: this.presetName.value,
      warm_up_exercises_attributes: []
    }

    const listItems = this.exercisesList.querySelectorAll('li')
    listItems.forEach((item) => {
      presetBody.warm_up_exercises_attributes.push(
        {
          exercise_id: item.querySelector('input[name*=exercise_id]').value,
          order: item.querySelector('input[name*=order]').value
        }
      )
    })

    this.savePresetRequest(presetBody)
  }

  savePresetRequest(presetBody) {
    $.ajax({
      url: '/gym_classes/warm_up_presets',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      data: JSON.stringify({ warm_up_preset: presetBody })
    })
    .done((data) => {
      const newOption = new Option(data.name, data.id, false, false)
      $(this.presetSelector).append(newOption).trigger('change')

      this.buttonStyle(true, data)
    })
    .fail((data) => this.buttonStyle(false, data.responseJSON))
  }

  buttonStyle(success, data) {
    const buttonClass = success ? 'btn-success' : 'btn-danger'

    this.savePresetButton.classList.remove('btn-primary')
    this.savePresetButton.classList.add(buttonClass)
    this.savePresetButton.innerHTML = data.message

    setTimeout(() => {
      this.savePresetButton.classList.remove(buttonClass)
      this.savePresetButton.classList.add('btn-primary')
      this.savePresetButton.innerHTML = 'Save preset'
    }, 5000)
  }
}
