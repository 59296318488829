import { App, createChannel } from 'src/cable.js';

$(document).ready(function(){

  if (!$('body').hasClass('maintenance-index')) return;

  function subscribe_to_channel(channel_name) {
    App.gymClassControlChannel = createChannel({
      channel: channel_name
    }, {
      connected: function() {
        if (window.location.href.split('?')[1] == 'reload_all'){
          App.gymClassControlChannel.broadcastMessage({ type: 'SoftwareUpdate-Reload' });
        };
      },
      disconnected: function() {},
      received: function(data) {},
      broadcastMessage: function(data) {
        this.perform('broadcast_from_client', data);
      }
    });
  };

  function broadcastMessage(message) {
    App.gymClassControlChannel.broadcastMessage(message);
  };

  function refreshServerAfterUpdate(){
    setTimeout(function(){ window.location.href += '?reload_all' },10000);
  };

  function updateBtnClickHandler(event){
    var self = $(this);
    self.addClass('disabled').html('Checking');
    $.get('/maintenance/check_for_updates', function(data){
      if (data.filename){
        var update = confirm('Version ' + data.version + ' Available, Would You Like To Update?');
        if (update){
          $.post('/maintenance/install_updates', { filename: data.filename}).always(function(){
            self.html('Installing');
            refreshServerAfterUpdate();
          });
        } else {
          self.removeClass('disabled').html('Check');
        };
      } else {
        self.removeClass('disabled').html('Check');
        alert('Current Version Up To Date');
      };
    });
  };

  subscribe_to_channel("GymClassControlChannel");

  $("#update-btn").on('click', updateBtnClickHandler);

});
