$(document).ready(function(){

  if (!$('body').hasClass('loop_lists-index')) return;

  function importFormForNewLoopList(){
    $.ajax({
      url: '/loop_lists/new?name=' + $('#loop_list_name').val(),
      method: 'GET',
      success: function(data){
        $('#loop-list-form-container').html(data);
      }
    });
  };

  function importFormForLoopList(loop_list_id){
    $.ajax({
      url: '/loop_lists/' + loop_list_id + '/edit',
      method: 'GET',
      success: function(data){
        $('#loop-list-form-container').html(data);
      }
    });
  };

  $('.edit-loop-list').on('click', function(){
    var loop_list_id = $(this).attr('data-id');
    importFormForLoopList(loop_list_id);
  });

  $('.publish-loop-list, .unpublish-loop-list').on('click', function(){
    var loop_list_id = $(this).attr('data-id');
    var endpoint = $(this).hasClass('publish-loop-list') ? 'publish' : 'unpublish';
    $.ajax({
      url: '/loop_lists/' + loop_list_id + '/' + endpoint,
      beforeSend: function(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      method: 'POST',
      success: function(data){
        location.reload();
      }
    });
  });

  $('#new-loop-list-btn').on('click', importFormForNewLoopList);

  ///////////////////////////////////////
  ///
  /// VALIDATIONS
  ///
  ///////////////////////////////////////

  var valid = true;
  var messages = [];

  function isValidTime(time){
    if (time === '') return true;
    return /^(|[0-5])[0-9]:[0-5][0-9]$/.test(time);
  }

  function validateTimeFields(timeFields){
    $(timeFields).each(function(i){
      if (!isValidTime($(this).val())){
        valid = false;
        $(this).addClass('form-invalid-field');
        messages.push('You have entered an invalid time.');
      };
    });
  }

  function validateVideoAndTime(){
    for (var i = 0; i < 30; i++){
      var time = $('input[data-validate="time"]').eq(i);
      var video  = $('#loop_list_loop_list_items_attributes_' + i + '_video_ref1_id');
      var timeIsBlank = time.val() == '' || time.val() == '0:00';
      if ((!timeIsBlank && video.val() == '') || (timeIsBlank && video.val() != '') ){
        valid = false;
        time.addClass('form-invalid-field');
        video.addClass('form-invalid-field');
        messages.push('You must have both an asset and a time');
      };
    };
  };

  function displayMessages(){
    $("#form-error-messages").html(messages.join('. '));
  }

  function resetInvalidatedFields(){
    $('.form-invalid-field').removeClass('form-invalid-field');
  };

  function onSubmitClickHandler(event){
    var timeFields = $('input[data-validate="time"]');

    // Reset previous validations
    resetInvalidatedFields();
    valid = true;
    messages = [];
    displayMessages();

    validateTimeFields(timeFields);
    validateVideoAndTime();

    if (!valid){
      displayMessages();
      event.preventDefault();
    };
  };

  $('#loop-list-form-container').on('click', 'input[type="submit"]', onSubmitClickHandler);
  $('#publish_loop_lists').select2();
});
