require('@rails/ujs').start()
require('@rails/actioncable')
require('@rails/actiontext')
require('trix')
require('bootstrap')
require('select2')
require( 'datatables.net-dt')

require('src/cable.js')

let control_folder = require.context('src/control/')
control_folder.keys().forEach(control_folder);
