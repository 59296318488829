import { createConsumer } from "@rails/actioncable"

var App = App || {};

const createChannel = (...args) => {
  if (!App.cable) {
    App.cable = createConsumer();
  }

  return App.cable.subscriptions.create(...args);
};

export { App, createChannel }
