$(document).ready(function(){

  if (!$('body').hasClass('gym_classes-index')) return;

  function importFormForNewClass(){
    $.ajax({
      url: '/gym_classes/new?name=' + $('#new-gym-class-name').val() + "&type=" + $('#gym_class_class_format_type').val(),
      method: 'GET',
      success: function(data){
        $('#gym-class-form-container').html(data);
        $('.workout-video-dd').select2({theme: "bootstrap4"});
      }
    });
  };

  function importFormForClass(class_id){
    $.ajax({
      url: '/gym_classes/' + class_id + '/edit',
      method: 'GET',
      success: function(data){
        $('#gym-class-form-container').html(data);
        $('.workout-video-dd').select2({theme: "bootstrap4"});
      }
    });
  };

  $('.edit-class').on('click', function(){
    var class_id = $(this).attr('data-id');
    importFormForClass(class_id);
  });

  $('.publish-class, .unpublish-class').on('click', function(){
    var class_id = $(this).attr('data-id');
    var endpoint = $(this).hasClass('publish-class') ? 'publish' : 'unpublish';
    $.ajax({
      url: '/gym_classes/' + class_id + '/' + endpoint,
      beforeSend: function(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      method: 'POST',
      success: function(data){
        location.reload();
      }
    });
  });

  $('#new-class-btn').on('click', function(){
    var name = $('#new-gym-class-name');
    var type = $('#gym_class_class_format_type');
    if (name.val() == "" || type.val() == ""){
      name.addClass("form-invalid-field");
      type.addClass("form-invalid-field");
    } else {
      name.removeClass("form-invalid-field");
      type.removeClass("form-invalid-field");
      importFormForNewClass();
      name.val('');
    };
  });

  $('#gym-class-form-container').on('keyup', '#gym_class_name', function(){
    $('#gym-class-name-span').html( $(this).val() );
  });

  ///////////////////////////////////////
  ///
  /// VALIDATIONS
  ///
  ///////////////////////////////////////

  var valid = true;
  var messages = [];
  var defaultErrorMessage = 'Please correct the fields with Red Outlines';

  function isValidTime(time){
    if (time === '') return true;
    return /^(|[0-5])[0-9]:[0-5][0-9]$/.test(time);
  }

  function isValidNum(num){
    if (num === '') return true;
    return /^\d+$/.test(num);
  };

  function validateRounds(field, maxRounds, message){
    var rounds = $(field);
    if (!isValidNum(rounds.val()) || parseInt(rounds.val().length) > maxRounds){
      rounds.addClass("form-invalid-field");
      valid = false;
      messages.push(message);
    };
  };

  function validateDuration(field, maxDuration, message){
    var duration = $(field);
    if (!isValidTime(duration.val()) || timeGreaterThan(duration.val(), maxDuration)){
      duration.addClass("form-invalid-field");
      valid = false;
      messages.push(message);
    };
  };

  function validateValueLength(field, maxLength, message){
    var e = $(field);
    if (e.val().length > maxLength){
      valid = false;
      e.addClass('form-invalid-field');
      messages.push(message)
    };
  };

  function timeGreaterThan(time1, time2){
    time1Array = time1.split(':');
    time2Array = time2.split(':');
    time1Mins = parseInt(time1Array[0]);
    time1Secs = parseInt(time1Array[1]);
    time2Mins = parseInt(time2Array[0]);
    time2Secs = parseInt(time2Array[1]);
    return time1Mins > time2Mins || (time1Mins == time2Mins && time1Secs > time2Secs)
  };

  function validateWelcome(){
    validateDuration('#gym_class_segments_attributes_0_duration_time', '10:00', 'Welcome duration must be a xx:xx time less than or equal to 10:00');
  };

  function validateWarmup(){
    // Rounds
    validateRounds('#gym_class_segments_attributes_1_rounds', 2, 'Warmup rounds must be a number, maximum 2 digits')
    // Time per exercise
    validateDuration('#gym_class_segments_attributes_1_activity_duration', '10:00', 'Warmup time per exercise must be a xx:xx time less than or equal to 10:00');
    validateWarmupActivities();
  };

  function validateWarmupActivities(){
    for (var i = 0; i < 6; i++){
      var field = $("#gym_class_segments_attributes_5_activities_attributes_" + i + "_text1");
      if (field.val() != "") return;
    }
    var field = $("#gym_class_segments_attributes_5_activities_attributes_0_text1");
    field.addClass("form-invalid-field");
    valid = false;
    messages.push('At least one warmup activity is required');
  };

  function validateMudderMove(){
    validateMudderMoveText();
    // Validate duration
    validateDuration('#gym_class_segments_attributes_2_duration_time', '30:00', 'Mudder Move duration must be a xx:xx time less than or equal to 10:00');
  };

  function validateMudderMoveText(){
    var field = $("#gym_class_segments_attributes_2_activities_attributes_0_text1");
    if (field.val() == ""){
      field.addClass("form-invalid-field");
      valid = false;
      messages.push('Mudder Move text is required');
    };
  };

  function validateWorkoutInstruction(){
    validateDuration('#gym_class_segments_attributes_3_duration_time', '10:00', 'Workout Instruction duration must be a xx:xx time less than or equal to 10:00');
  };

  function validateWorkout(){
    // Workout Rounds
    validateRounds('#gym_class_segments_attributes_4_rounds', 2, 'Workout rounds must be a number, maximum 2 digits');
    // Time per station
    // validateDuration('#gym_class_segments_attributes_4_activity_duration', '10:00', 'Workout Time Per Station must be a xx:xx time less than or equal to 10:00');
    // Time to move
    // validateDuration('#gym_class_segments_attributes_4_move_time_time', '10:00', 'Workout Move Time must be a xx:xx time less than or equal to 5:00');
    // Rest Time
    // validateDuration('#gym_class_segments_attributes_4_rest_time_time', '5:00', 'Workout Rest Time must be a xx:xx time less than or equal to 5:00');
  };

  function validateCooldown(message){
    validateCooldownActivities();
    validateDuration('#gym_class_segments_attributes_5_duration_time', '30:00', 'Cooldown duration must be a xx:xx time less than or equal to 30:00');
  };

  function validateCooldownActivities(){
    for (var i = 0; i < 6; i++){
      var field = $("#gym_class_segments_attributes_1_activities_attributes_" + i + "_video_ref1_id");
      if (field.val() != "") return;
    }
    var field = $("#gym_class_segments_attributes_1_activities_attributes_0_video_ref1_id");
    field.addClass("form-invalid-field");
    valid = false;
    messages.push('At least one cooldown activity is required');
  };

  function validateStation(){
    for (var i = 0; i < 6; i++){
      var video_ref1 = $('#workout_station_' + i + '_video_ref1_id');
      var video_ref2 = $('#workout_station_' + i + '_video_ref2_id');
      var text1 = $('#workout_station_' + i + '_text1');
      var text2 = $('#workout_station_' + i + '_text2');
      var text3 = $('#workout_station_' + i + '_text3');
      var text4 = $('#workout_station_' + i + '_text4');

      // A station needs either a video_ref1, video_ref2, or both
      if (video_ref1.val() == '' && video_ref2.val() == ''){
        video_ref1.addClass('form-invalid-field');
        valid = false;
        messages.push('Station ' + (i + 1) + ' needs at least 1 exercise');
      };

      // A station needs at least 1 pair of Reps, Text
      if ((text1.val() == '' || text2.val() == '') && (text3.val() == '' || text4.val() == '')){
        valid = false;
        text1.addClass('form-invalid-field');
        text2.addClass('form-invalid-field');
        messages.push('Station ' + (i + 1) + ' needs at least 1 Reps, Text pair');
      };

      // Reps cannot be greater than 10 chars
      validateValueLength(text1, 10, 'Station ' + (i + 1) + ' reps cannot be longer than 10 characters');
      validateValueLength(text3, 10, 'Station ' + (i + 1) + ' reps cannot be longer than 10 characters');
      // Text cannot be greater than 10 chars
      validateValueLength(text2, 50, 'Station ' + (i + 1) + ' text cannot be longer than 50 characters');
      validateValueLength(text4, 50, 'Station ' + (i + 1) + ' text cannot be longer than 50 characters');
    };
  };

  function validateTabataFields(){
    // The form might not have tabata fields
    if ($('#tabata_work_1')){
      var valid = true;
      for (var i = 1; i <= 4; i++){
        if ($('#tabata_work_' + i).val() == ""){
          $('#tabata_work_' + i).addClass('form-invalid-field')
          valid = false;
        };
        if ($('#tabata_rest_' + i).val() == ""){
          $('#tabata_rest_' + i).addClass('form-invalid-field')
          valid = false;
        };
      };
      if (!valid) {
        messages.push('All work/rest duration fields need values');
      };
    };
  };

  function displayMessages(){
    $("#form-error-messages").html(messages.join('. '));
  }

  function resetInvalidatedFields(){
    $('.form-invalid-field').removeClass('form-invalid-field');
  };

  function onSubmitClickHandler(event){
    var timeFields = $('input[data-validate="time"]');
    var numFields = $('input[data-validate="digits"]');

    // Reset previous validations
    resetInvalidatedFields();
    valid = true;
    messages = [];
    displayMessages();

    validateWelcome();
    validateWarmup();
    validateMudderMove();
    validateWorkoutInstruction();
    validateWorkout();
    validateCooldown();
    validateStation();
    validateTabataFields();
    if (!valid){
      displayMessages();
      event.preventDefault();
    };
  };

  $('#gym-class-form-container').on('click', 'input[type="submit"]', onSubmitClickHandler);


  //////////////////////////////////////////////
  /// TABATA SECTION ADDITION AND SUBTRACTION //
  //////////////////////////////////////////////


  function onTabataAdditionButtonClick(event){
    event.preventDefault();
    var nextNum = $(this).attr('data-next');
    $.get('/gym_classes/tabata_section/' + nextNum, function(html){
      $('#tabata-sections-container').append(html);
    });
    $(this).attr('data-next', parseInt(nextNum) + 1);
  };

  function onTabataSubtractionButtonClick(event){
    event.preventDefault();
    var deleteNum = $(this).attr('data-delete');
    $('#tabata_work_' + deleteNum).parent().parent().remove();
    $('#tabata_rest_' + deleteNum).parent().parent().remove();
  };

  $('body').on('click', '.tabata-addition-button', onTabataAdditionButtonClick);
  $('body').on('click', '.tabata-subtraction-button', onTabataSubtractionButtonClick);
});
