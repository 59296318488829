import { Exercises } from 'src/control/exercises_class.js'

export class CooldownExercises extends Exercises {
  createHiddenFields(listItem) {
    const regex = new RegExp(/NEW_COOLDOWN_EXERCISE_ID/g)

    let exerciseFields = $(this.exerciseFields.replace(regex, this.originalExercisesNumber))
    exerciseFields.each((_, field) => listItem.append(field))

    listItem.querySelector('input[name*=exercise_id]').value = this.exerciseSelector.value
  }

  loadPresetExercises(exercises) {
    this.exercisesList.querySelectorAll('li').forEach(listItem => this.deleteExercise(listItem))

    exercises.forEach(exercise => {
      this.addPresetExercise(exercise)
    })
  }

  addPresetExercise(exercise) {
    const listItem = this.createListItemForPreset(exercise.name)

    this.createHiddenFieldsForPreset(listItem, exercise.id)

    listItem.querySelector('.delete-button').addEventListener('click', (event) => {
      event.preventDefault()
      this.deleteExercise(listItem)
    })

    this.exercisesList.append(listItem)

    this.updateExerciseListOrder()

    this.exercisesNumber++
  }

  createListItemForPreset(exerciseName) {
    const listItemString = `
      <li class="list-group-item d-flex justify-content-between align-items-center">
        ${exerciseName}
        <span>
          <i class="reorder-button btn p-1 fa fa-bars fa-lg"></i>
          <i class="delete-button btn p-1 ml-2 text-danger fa fa-trash fa-lg"></i>
        </span>
      </li>
    `
    return $(listItemString)[0]
  }

  createHiddenFieldsForPreset(listItem, exerciseId) {
    const regex = new RegExp(/NEW_COOLDOWN_EXERCISE_ID/g)

    let exerciseFields = $(this.exerciseFields.replace(regex, this.exercisesNumber))
    exerciseFields.each((_, field) => listItem.append(field))

    listItem.querySelector('input[name*=exercise_id]').value = exerciseId
  }
}
