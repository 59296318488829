import { GymClass } from 'src/control/gym_class_class.js'

window.addEventListener( "pageshow", (event) => {
  var historyTraversal = event.persisted ||
                         (typeof window.performance != "undefined" &&
                          window.performance.navigation.type === 2)
  if (historyTraversal) {
    window.location.reload();
  }
})

document.addEventListener('DOMContentLoaded', () => {
  new GymClass
})
