import Sortable from 'sortablejs'
import { unescapeHTML } from 'src/control/utils.js'

export class Exercises {
  constructor(exercisesElement, editable = false) {
    this.exercisesElement = exercisesElement
    this.editable = editable

    this.exercisesList = exercisesElement.querySelector('.list')
    this.addExerciseButton = exercisesElement.querySelector('.add-exercise')
    this.confirmButton = exercisesElement.querySelector('.confirm-exercise')
    this.newExerciseContainer = exercisesElement.querySelector('.new-exercise-container')

    this.exerciseFields = unescapeHTML(exercisesElement.dataset.exerciseFields)
    this.exercisesNumber = parseInt(exercisesElement.dataset.exercisesNumber)
    this.originalExercisesNumber = parseInt(exercisesElement.dataset.exercisesNumber)

    this.exerciseSelector = this.newExerciseContainer.querySelector('.exercise-selector')
    $(this.exerciseSelector).select2({ theme: 'bootstrap4' })

    this.initSortable()
    this.initList()

    this.listenToEvents()
  }

  initSortable() {
    Sortable.create(this.exercisesList, {
      handle: '.reorder-button',
      onEnd: () => { this.updateExerciseListOrder() }
    })
  }

  listenToEvents() {
    this.addExerciseButton.addEventListener('click', (event) => {
      event.preventDefault()
      this.setConfirmButtonAsAdd()
      this.clearInputs()
      this.openNewExerciseContainer()
    })

    this.confirmButton.addEventListener('click', (event) => {
      event.preventDefault()
      if (this.confirmButton.dataset.action == 'add') {
        this.addExercise()
      } else if (this.confirmButton.dataset.action == 'edit') {
        this.editExercise()
      }
    })
  }

  openNewExerciseContainer() {
    this.addExerciseButton.classList.add('d-none')
    this.newExerciseContainer.classList.remove('d-none')
  }

  closeNewExerciseContainer() {
    this.addExerciseButton.classList.remove('d-none')
    this.newExerciseContainer.classList.add('d-none')
  }

  addExercise() {
    const listItem = this.createListItem()

    this.createHiddenFields(listItem)

    listItem.querySelector('.delete-button').addEventListener('click', (event) => {
      event.preventDefault()

      if (confirm('Do you want to delete the exercise?')) {
        this.deleteExercise(listItem)
      }
    })

    if (this.editable) {
      listItem.querySelector('.edit-button').addEventListener('click', (event) => {
        event.preventDefault()
        this.onEditClick(listItem)
      })
    }

    this.exercisesList.append(listItem)

    this.updateExerciseListOrder()
    this.closeNewExerciseContainer()
    this.clearInputs()

    this.exercisesNumber++
    this.originalExercisesNumber++

    this.exercisesElement.dataset.exercisesNumber = this.exercisesNumber
    this.exercisesElement.dispatchEvent(new Event('duration-update'))
  }

  createListItem() {
    const listItemString = `
      <li class="list-group-item d-flex justify-content-between align-items-center">
        ${this.exerciseSelector.querySelector('option:checked').innerHTML}
        <span>
          <i class="reorder-button btn p-1 fa fa-bars fa-lg"></i>
          <i class="delete-button btn p-1 ml-2 text-danger fa fa-trash fa-lg"></i>
        </span>
      </li>
    `
    return $(listItemString)[0]
  }

  setConfirmButtonAsAdd() {
    this.confirmButton.dataset.action = 'add'
    this.confirmButton.classList.remove('btn-warning')
    this.confirmButton.classList.add('btn-primary')
    this.confirmButton.innerHTML = 'Select exercise'
  }

  onEditClick(listItem) {
    this.exerciseToEdit = listItem

    this.setConfirmButtonAsEdit()
    this.loadEditableFields(listItem)
    this.openNewExerciseContainer()
  }

  editExercise() {
    const exerciseName = this.exerciseSelector.querySelector('option:checked').innerHTML
    this.exerciseToEdit.querySelector('.exercise-name').innerHTML = exerciseName

    this.changeFieldsValue(this.exerciseToEdit)

    this.closeNewExerciseContainer()
  }

  setConfirmButtonAsEdit() {
    this.confirmButton.dataset.action = 'edit'
    this.confirmButton.classList.remove('btn-primary')
    this.confirmButton.classList.add('btn-warning')
    this.confirmButton.innerHTML = 'Edit exercise'
  }

  deleteExercise(listItem) {
    const deleteInput = listItem.querySelector('input[name*=_destroy]')
    deleteInput.value = true

    for(const input of listItem.querySelectorAll('input')) {
      this.exercisesList.append(input);
    }

    listItem.remove()

    this.updateExerciseListOrder()
    this.exercisesNumber--

    this.exercisesElement.dataset.exercisesNumber = this.exercisesNumber
    this.exercisesElement.dispatchEvent(new Event('duration-update'))
  }

  initList() {
    this.exercisesList.querySelectorAll('li').forEach(listItem => {
      listItem.querySelector('.delete-button').addEventListener('click', event => {
        event.preventDefault()

        if (confirm('Do you want to delete the exercise?')) {
          this.deleteExercise(listItem)
        }
      })

      if (this.editable) {
        listItem.querySelector('.edit-button').addEventListener('click', event => {
          event.preventDefault()
          this.onEditClick(listItem)
        })
      }
    })
  }

  updateExerciseListOrder() {
    let index = 0

    this.exercisesList.querySelectorAll('li').forEach(item =>{
      const orderInput = item.querySelector('input[name*=order]')

      orderInput.value = index
      index++
    })
  }

  clearInputs() {
    this.newExerciseContainer.querySelectorAll('input, trix-editor').forEach(input => {
      input.innerHTML = ""
      input.value = ""

      if (input.type == 'color') {
        input.value = '#FFFFFF'
      }
    })
  }
}
