import { Exercises } from 'src/control/exercises_class.js'

export class StationExercises extends Exercises {
  createListItem() {
    const listItemString = `
      <li class="list-group-item d-flex justify-content-between align-items-center">
        <div>
          <span class="d-block exercise-name">
            ${this.exerciseSelector.querySelector('option:checked').innerHTML}
          </span>

          <i class="d-block repetitions">
            ${this.newExerciseContainer.querySelector('.repetitions').value}
          </i>
        </div>
        <span>
          <i class="reorder-button btn p-1 fa fa-bars fa-lg"></i>
          <i class="edit-button btn ml-2 mr-2 p-1 fa fa-pencil fa-lg"></i>
          <i class="delete-button btn p-1 ml-2 text-danger fa fa-trash fa-lg"></i>
        </span>
      </li>
    `
    return $(listItemString)[0]
  }

  setHiddenFieldsValues(listItem) {
    const exerciseId = this.exerciseSelector.value
    const description = this.newExerciseContainer.querySelector('.description').value
    const repetitions = this.newExerciseContainer.querySelector('.repetitions').value
    const color = this.newExerciseContainer.querySelector('.highlight-color').value

    listItem.querySelector('input[name*=exercise_id]').value = exerciseId
    listItem.querySelector('input[name*=description]').value = description
    listItem.querySelector('input[name*=repetitions]').value = repetitions
    listItem.querySelector('input[name*=highlight_color]').value = color
  }

  createHiddenFields(listItem) {
    const regex = new RegExp(/NEW_STATION_EXERCISE_ID/g)

    let exerciseFields = $(this.exerciseFields.replace(regex, this.originalExercisesNumber))
    exerciseFields.each((_, field) => listItem.append(field))

    this.setHiddenFieldsValues(listItem)
  }

  changeFieldsValue(listItem) {
    const repetitons = this.newExerciseContainer.querySelector('.repetitions').value
    this.exerciseToEdit.querySelector('.repetitions').innerHTML = repetitons

    this.setHiddenFieldsValues(listItem)
  }

  loadEditableFields(listItem) {
    const exerciseId = listItem.querySelector('input[name*=exercise_id]').value
    const description = listItem.querySelector('input[name*=description]').value
    const repetitions = listItem.querySelector('input[name*=repetitions]').value
    const color = listItem.querySelector('input[name*=highlight_color]').value

    this.exerciseSelector.value = exerciseId
    this.newExerciseContainer.querySelector('.description').value = description
    this.newExerciseContainer.querySelector('.repetitions').value = repetitions
    this.newExerciseContainer.querySelector('.highlight-color').value = color

    $(this.exerciseSelector).trigger('change')
  }
}
