import moment from 'moment'

$(document).ready(function() {
  const monthSelect = document.getElementById('month-select')
  if (monthSelect) {
    monthSelect.value = moment().format('YYYY-MM')
  }

  const table = $('#gym_classes_table').DataTable({
    searching: true,
    dom: 'tp',
    columnDefs: [{
      targets: 5,
      orderable: false
    }],
    initComplete: () => {
      $('#gym_classes_table').show()
      $('.load-table').hide()
    }
  })

  $(monthSelect).change(() => { table.draw() })

  $('.month-down').click(() => {
    monthSelect.stepDown()
    $(monthSelect).trigger('change')
  })

  $('.month-up').click(() => {
    monthSelect.stepUp()
    $(monthSelect).trigger('change')
  })

  $('.search-input').keyup(function() {
    table.search($(this).val())
    table.draw()
  })
})

$.fn.dataTable.ext.classes.sPageButton = 'btn btn-light mx-1'
$.fn.dataTable.ext.classes.sPageButtonActive = 'btn-dark text-white'
$.fn.dataTable.ext.classes.sPaging = 'dataTables_paginate text-center mt-4 paging_'

$.fn.dataTable.ext.search.push(function (settings, searchData) {
  if (moment($('#month-select').val()).isSame(searchData[0], 'month')) {
      return true;
  }

  return false;
});
