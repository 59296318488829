import ohSnap from 'src/ohsnap.js';

$(document).ready(function() {
  const gymClassPlayer = document.querySelector('#gym-class-player')
  
  if (gymClassPlayer) {
    const startButton = gymClassPlayer.querySelector('#btn_start_class')
    const stopButton = gymClassPlayer.querySelector('#btn_stop_class')
    const pauseButton = gymClassPlayer.querySelector('#btn_pause_class')
    const previousButton = gymClassPlayer.querySelector('#btn-skip-back')
    const nextButton = gymClassPlayer.querySelector('#btn-skip-forward')
    const commingUpButton = gymClassPlayer.querySelector('#btn_coming_up_class')
    const classSelect = gymClassPlayer.querySelector('#select_gym_classes')

    startButton.addEventListener('click', () => {
      $.ajax({
        url: '/player/start',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        data: JSON.stringify({ id: classSelect.querySelector('option:checked').value })
      })
      .done(() => {
        ohSnap('Started class', { color: 'green' });
      })
    })

    stopButton.addEventListener('click', () => {
      $.ajax({
        url: '/player/stop',
        method: 'POST',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        }
      })
      .done(() => {
        ohSnap('Stopped class', { color: 'red' });
      })
    })

    pauseButton.addEventListener('click', () => {
      $.ajax({
        url: '/player/pause',
        method: 'POST',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        }
      })
      .done(() => {
        ohSnap('Paused class', { color: 'yellow' });
      })
    })

    previousButton.addEventListener('click', () => {
      $.ajax({
        url: '/player/jump_to_previous_section',
        method: 'POST',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        }
      })
      .done(() => {
        ohSnap('Jumped to previous section', { color: 'green' });
      })
    })

    nextButton.addEventListener('click', () => {
      $.ajax({
        url: '/player/jump_to_next_section',
        method: 'POST',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        }
      })
      .done(() => {
        ohSnap('Jumped to next section', { color: 'green' });
      })
    })

    commingUpButton.addEventListener('click', () => {
      $.ajax({
        url: '/player/comming_up',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        data: JSON.stringify({ id: classSelect.querySelector('option:checked').value })
      })
      .done(() => {
        ohSnap('Comming up class added', { color: 'green' });
      })
    })
  }

  const menuToggle = document.querySelector('#mobile-menu-toggle')
  menuToggle.addEventListener('click', () => {
    const menuContainer = document.querySelector(".header-logout-item")
    menuContainer.classList.toggle('active')
  })
})
