import { durationToSeconds, secondsToDuration } from './utils.js'
import { CooldownExercises } from './cooldown_exercises_class.js'

export class Cooldown {
  constructor(container) {
    this.container = container

    this.initExercises()
    this.initPresets()
    this.initDuration()
  }

  initExercises() {
    this.exercisesContainer = this.container.querySelector('.exercises')
    this.exercisesList = this.exercisesContainer.querySelector('.list')
    this.exercisesInstance = new CooldownExercises(this.exercisesContainer)
  }

  initPresets() {
    this.presetSelector = this.container.querySelector('.preset-select')
    this.presetName = this.container.querySelector('.preset-name')

    this.savePresetButton = this.container.querySelector('.save-preset')
    this.savePresetButton.addEventListener('click', () => {
      event.preventDefault()
      this.savePreset()
    })

    $(this.presetSelector).select2({ theme: 'bootstrap4' })
    $(this.presetSelector).on('select2:select', () => this.loadPreset())
  }

  initDuration() {
    this.duration = 0
    this.durationInput = this.container.querySelector('input[name*=duration]')
    this.durationInput.addEventListener('change', () => this.calculateDuration())
    this.calculateDuration()
  }

  loadPreset() {
    let alert = confirm('Do you want to load a new cooldown preset?')

    if (this.presetSelector.value.length && alert) {
      $.ajax({
        url: `/gym_classes/cooldown_presets/${this.presetSelector.value}`
      })
      .done(presetData => {
        const duration = secondsToDuration(presetData.duration)
        this.container.querySelector('input[name*=duration]').value = duration
        this.exercisesInstance.loadPresetExercises(presetData.exercises)
      })
    } else if (!alert) {
      this.presetSelector.value = ''
    }
  }

  savePreset() {
    let presetBody = {
      preset: true,
      preset_name: this.presetName.value,
      duration: durationToSeconds(
        this.container.querySelector('input[name*=duration]').value
      ),
      cooldown_exercises_attributes: []
    }

    const listItems = this.exercisesList.querySelectorAll('li')
    listItems.forEach((item) => {
      presetBody.cooldown_exercises_attributes.push(
        {
          exercise_id: item.querySelector('input[name*=exercise_id]').value,
          order: item.querySelector('input[name*=order]').value
        }
      )
    })

    $.ajax({
      url: '/gym_classes/cooldown_presets',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      data: JSON.stringify({ cooldown_preset: presetBody })
    })
    .done((data) => {
      const newOption = new Option(data.name, data.id, false, false)
      $(this.presetSelector).append(newOption).trigger('change')

      this.buttonStyle(true, data)
    })
    .fail((data) => this.buttonStyle(false, data.responseJSON))
  }

  buttonStyle(success, data) {
    const buttonClass = success ? 'btn-success' : 'btn-danger'

    this.savePresetButton.classList.remove('btn-primary')
    this.savePresetButton.classList.add(buttonClass)
    this.savePresetButton.innerHTML = data.message

    setTimeout(() => {
      this.savePresetButton.classList.remove(buttonClass)
      this.savePresetButton.classList.add('btn-primary')
      this.savePresetButton.innerHTML = 'Save preset'
    }, 5000)
  }

  calculateDuration() {
    this.duration = durationToSeconds(this.durationInput.value)

    this.container.dispatchEvent(new Event('duration-update'))
  }
}
