import { unescapeHTML, refreshDurationPickers, durationToSeconds, secondsToDuration } from './utils.js'
import { StationExercises } from './station_exercises_class.js'

export class Round {
  constructor(container) {
    this.container = container
    this.duration = 0

    this.roundType = this.container.querySelector('select[name*=round_type]')
    this.restTime = this.container.querySelector('input[name*=rest_time]')
    this.timeToMove = this.container.querySelector('input[name*=time_to_move]')
    if (this.roundType.value == 'tabata') {
      const tabataDurationInput = this.container.querySelector('input[name*=tabata_duration_per_exercise]')
      if (tabataDurationInput) {
        this.tabataDuration = durationToSeconds(tabataDurationInput.value)
      } else {
        this.tabataDuration = null
      }
      const tabataRestInput = this.container.querySelector('input[name*=tabata_rest_time')
      if (tabataRestInput) {
        this.tabataRest = durationToSeconds(tabataRestInput.value)
      } else {
        this.tabataRest = null
      }
    } else {
      this.tabataDuration = 0
      this.tabataRest = 0
    }


    this.initRoundInputs()
    this.initStations()
    this.initCopyAndPaste()

    this.calculateDuration()
    this.listenToEvents()
  }

  initRoundInputs() {
    const inputsRow = this.container.querySelector('.inputs-row')
    inputsRow.innerHTML = ''

    if (this.roundType.value == 'tabata' && inputsRow.innerHTML == '') {
      const template = this.container.querySelector('.tabata_round_inputs')

      inputsRow.appendChild(template.content.cloneNode(true))

      const tabataDuration = inputsRow.querySelector('input[name*=tabata_duration_per_exercise]')
      if (this.tabataDuration != null) {
        tabataDuration.value = secondsToDuration(this.tabataDuration)
      } else {
        var input = inputsRow.querySelector('input[name*=tabata_duration_per_exercise]')
        input.value = secondsToDuration(input.getAttribute('value'))
      }

      const tabataRest = inputsRow.querySelector('input[name*=tabata_rest_time]')
      if (this.tabataDuration != null) {
        tabataRest.value = secondsToDuration(this.tabataRest)
      } else {
        var input = inputsRow.querySelector('input[name*=tabata_rest_time]')
        input.value = secondsToDuration(input.getAttribute('value'))
      }
    }
  }

  initStations() {
    this.stationsContainer = this.container.querySelector('.stations-container')
    this.stationsNumberInput = this.container.querySelector('.stations-number')
    this.stationsNumber = this.container.dataset.stationsNumber
    this.stationFields = unescapeHTML(this.container.dataset.stationFields)
    this.stationsTimeAutopopulated = false

    if (this.stationsNumber == 0) {
      this.addStations(6)
      this.stationsNumber = this.stationsNumberInput.value = 6
    } else {
      this.stationsContainer.querySelectorAll('select[name*=tvs_assigned]').forEach((select) => {
        $(select).select2({ theme: 'bootstrap4' })
      })

      this.stationsContainer.querySelectorAll('.exercises').forEach((exercisesList) => {
        new StationExercises(exercisesList, true)
      })
    }
  }

  initCopyAndPaste() {
    this.copyButton = this.container.querySelector('.copy-button')
    this.pasteButton = this.container.querySelector('.paste-button')
    this.deleteButton = this.container.querySelector('.delete-button')

    $(this.copyButton).tooltip()
    $(this.pasteButton).tooltip()
    $(this.deleteButton).tooltip()
  }

  listenToEvents() {
    this.roundType.addEventListener('change', () => { this.roundTypeChanged() })

    const timeInputs = this.container.querySelectorAll(
      'input[name*="[rest_time]"], input[name*=time_to_move], input[name*=time_at_station]'
    )

    timeInputs.forEach((input) => {
      input.addEventListener('change', () => this.calculateDuration())
    })

    this.copyButton.addEventListener('click', () => { this.copyRound() })
    this.pasteButton.addEventListener('click', () => { this.pasteRound() })
    this.deleteButton.addEventListener('click', () => { this.deleteRound() })

    this.stationsNumberInput.addEventListener('change', (event) => {
      event.preventDefault()
      this.stationsNumberChange()
    })

    const firstTimeAtStation = this.stationsContainer.querySelector('input[name*=time_at_station]')
    firstTimeAtStation.addEventListener('blur', () => {
      this.autoPopulateTimeAtStation()
      this.stationsTimeAutopopulated = true
    })
  }

  calculateDuration() {
    let duration = 0;

    const timeInputs = this.container.querySelectorAll(
      'input[name*="[rest_time]"], input[name*=time_at_station]'
    )

    timeInputs.forEach((input) => { duration += durationToSeconds(input.value) })

    const timeToMoveInput = this.container.querySelector('input[name*=time_to_move]')
    duration += durationToSeconds(timeToMoveInput.value) * (this.stationsNumber - 1)

    this.duration = duration
    this.container.dispatchEvent(new Event('duration-update'))
  }

  roundTypeChanged() {
    this.initRoundInputs()

    this.stationsTimeAutopopulated = false
  }

  stationsNumberChange() {
    if (this.stationsNumberInput.value < 1) { return }

    const stationsAfterChange = parseInt(this.stationsNumberInput.value) - this.stationsNumber

    if (stationsAfterChange < 0) {
      this.removeStations(stationsAfterChange)
    } else if (stationsAfterChange > 0) {
      this.addStations(stationsAfterChange)

      if (this.stationsTimeAutopopulated) {
        this.autoPopulateTimeAtStation()
      }
    }

    this.stationsNumber = this.stationsNumberInput.value
    this.calculateDuration()
  }

  addStations(number) {
    for (let i = 0; i < number; i++) {
      const regex = new RegExp(/NEW_STATION_ID/g)
      const stationDisplayNumber = parseInt(this.stationsNumber) + i + 1

      let stationFields = this.stationFields.replace(
        'Station NEW_STATION_ID',
        `Station ${stationDisplayNumber}`
      )
      stationFields = stationFields.replace(regex, parseInt(this.stationsNumber) + i)

      $(this.stationsContainer).append(stationFields)

      const addedStation = this.getLastStation()

      $(addedStation.querySelector('select[name*=tvs_assigned]')).select2({ theme: 'bootstrap4' })
      $(addedStation.querySelector('select[name*=tvs_assigned]')).val(stationDisplayNumber)
                                                                 .trigger('change')

      addedStation.addEventListener('change', () => this.calculateDuration())

      new StationExercises(addedStation.querySelector('.exercises'), true)

      refreshDurationPickers()
    }
  }

  removeStations(number) {
    for (let i = 0; i < Math.abs(number); i++) {
      const lastStation = this.getLastStation()

      if (lastStation) {
        const hiddenInputs = lastStation.querySelectorAll('input[type=hidden]')

        hiddenInputs.forEach((input) => {
          if (input.name.includes('_destroy')) {
            input.value = true
          }

          this.stationsContainer.append(input)
        })

        lastStation.remove()
      }
    }
  }

  getLastStation() {
    return this.stationsContainer.querySelector('.station:last-of-type')
  }

  autoPopulateTimeAtStation() {
    const timeInputs = this.stationsContainer.querySelectorAll('input[name*=time_at_station]')

    for (let i = 1; i < timeInputs.length; i++) {
      timeInputs[i].value = timeInputs[0].value
    }

    this.calculateDuration()
  }

  copyRound() {
    this.container.dataset.stationsNumber = this.stationsNumber
    this.container.dispatchEvent(new Event('round-copied'))

    setTimeout(() => $(this.copyButton).tooltip('hide'), 2000)
  }

  pasteRound() {
    this.container.dispatchEvent(new Event('round-pasted'))
  }

  deleteRound() {
    const roundIndex = parseInt(this.container.querySelector('.round-title').innerText.replace( /^\D+/g, '')) - 1
    this.container.dispatchEvent(new CustomEvent('round-deleted', {'detail': { 'roundNumber': roundIndex }}))
  }

  stationsMatchTime() {
    const timeInputs = this.stationsContainer.querySelectorAll('input[name*=time_at_station')
    let previousInputValue = durationToSeconds(timeInputs[0].value)

    for (let input of timeInputs) {
      const inputValue = durationToSeconds(input.value)
      if (previousInputValue == inputValue) {
        previousInputValue = inputValue
      } else {
        return false
      }
    }

    return true
  }
}
