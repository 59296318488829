$(document).ready(function(){

  if (!$('body').hasClass('video_refs-index')) return;

  function importFormForNewVideoRef(){
    $.ajax({
      url: '/video_refs/new?description=' + $('#video_ref_tmp_description').val(),
      method: 'GET',
      success: function(data){
        $('#video-ref-form-container').html(data);
      }
    });
  };

  function importFormForVideoRef(){
    $.ajax({
      url: '/video_refs/' + $('#video-ref-select').val() + '/edit',
      method: 'GET',
      success: function(data){
        $('#video-ref-form-container').html(data);
      }
    });
  };

  $('#new-video-ref-btn').on('click', importFormForNewVideoRef);
  $('#video-ref-select').on('change', importFormForVideoRef);

  ///////////////////////////////////////
  ///
  /// VALIDATIONS
  ///
  ///////////////////////////////////////

  var valid = true;
  var messages = [];


  function displayMessages(){
    $("#form-error-messages").html(messages.join('. '));
  }

  function resetInvalidatedFields(){
    $('.form-invalid-field').removeClass('form-invalid-field');
  };

  function validateFile(){
    var videoFile = $('#video_ref_file_name');
    var form = $("form");
    if (videoFile.val() == '' && form.hasClass('new_video_ref')){
      valid = false;
      videoFile.addClass('form-invalid-field');
      messages.push('The video must have a description');
    };
  };

  function validateDescription(){
    var videoDesc = $('#video_ref_description');
    if (videoDesc.val() == ''){
      valid = false;
      videoDesc.addClass('form-invalid-field');
      messages.push('The video must have a description.');
    } else if (videoDesc.val().length > 55){
      videoDesc.addClass("form-invalid-field");
      valid = false;
      messages.push('Description cannot be longer than 55 characters');
    };
  }

  function validateType(){
    var videoType = $('#video_ref_video_type');
    if (videoType.val() == ''){
      valid = false;
      videoType.addClass('form-invalid-field');
      messages.push('The video must have a type.');
    };
  };

  function onSubmitClickHandler(event){
    var timeFields = $('input[data-validate="time"]');

    // Reset previous validations
    resetInvalidatedFields();
    valid = true;
    messages = [];
    displayMessages();

    validateFile();
    validateDescription();
    validateType();

    if (!valid){
      displayMessages();
      event.preventDefault();
    };
  };

  $('#video-ref-form-container').on('click', 'input[type="submit"]', onSubmitClickHandler);
  $('#video-ref-select').select2({ theme: 'bootstrap4' });
});
